import intlTelInput from 'intl-tel-input';

export default function checkoutPhone() {
    const phoneInputs = document.querySelectorAll('input[type="tel"]');

    const checkLabelPosition = item => {
        const parentIti = item.closest('.iti');

        if (parentIti) {
            const flag = parentIti.querySelector('.iti__flag-container');
            const label = parentIti.nextElementSibling;
            const rect = flag.getBoundingClientRect();
            const { width } = rect;

            label.style.left = `${width + 24}px`;
        }
    };

    if (phoneInputs) {
        phoneInputs.forEach(item => {
            const input = intlTelInput(item, {
                separateDialCode: true,
                nationalMode: false,
                autoInsertDialCode: true,
                initialCountry: 'pl',
            });

            const itemVal = item.value;
            if (itemVal !== '') {
                const parentIti = item.closest('.iti');
                if (parentIti) {
                    const label = parentIti.nextElementSibling;
                    label.classList.add('focused');
                }
            }
            
            checkLabelPosition(item);

            item.addEventListener('keyup', () => {
                const { value } = item;
                const parentIti = item.closest('.iti');

                if (parentIti) {
                    const label = parentIti.nextElementSibling;
                    if (value !== '') {
                        label.classList.add('filled');
                    } else {
                        label.classList.remove('filled');
                    }
                }
            });

            item.addEventListener('focus', () => {
                const parentIti = item.closest('.iti');

                if (parentIti) {
                    const label = parentIti.nextElementSibling;
                    label.classList.add('focused');
                }
            });

            item.addEventListener('blur', () => {
                const parentIti = item.closest('.iti');

                if (parentIti) {
                    const label = parentIti.nextElementSibling;
                    label.classList.remove('focused');
                }
            });

            item.addEventListener('countrychange', function () {
                checkLabelPosition(item);
            });
        });
    }
}
