export default function megaMenu(){

    document.querySelectorAll('.js-mega-menu').forEach(function(megaMenu) {
        var subcategoryLinks = megaMenu.querySelectorAll('.js-mega-menu-link');
    
        subcategoryLinks.forEach(function(link) {
            link.addEventListener('mouseenter', function() {

                subcategoryLinks.forEach(function(otherLink) {
                    otherLink.classList.remove('-active');
                });
    
                link.classList.add('-active');
    
                var targetId = link.getAttribute('data-target');
                var imageTargetId = link.getAttribute('data-image-target');
    
                megaMenu.querySelectorAll('.js-mega-menu-subcat-content, .js-mega-menu-subcat-image').forEach(function(content) {
                    content.classList.remove('visible');
                });
    
                if (targetId) {
                    megaMenu.querySelector(targetId).classList.add('visible');
                }
    
                if (imageTargetId) {
                    megaMenu.querySelector(imageTargetId).classList.add('visible');
                }
            });
        });
    });
    
    
    
    
}