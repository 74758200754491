import SimpleLightbox from 'simplelightbox';
export default function singleProductGallery() {
    const galleryItems = document.querySelectorAll(
        '.single-product-gallery__col a'
    );

    if (!galleryItems || galleryItems.length === 0) return;

    const lightboxGallery = new SimpleLightbox(
        `.single-product-gallery__col a`,
        {}
    );
}
