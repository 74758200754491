export default function myaccountNav() {
    $ = jQuery;

    $('#select2-account-navigation').on('select2:select', function (e) {
        var url = $(this).val();
        if (url) {
            window.location = url;
        }
    });

    var currentUrl = window.location.href;

    $('#select2-account-navigation option').each(function () {
        if (this.value === currentUrl) {
            $('#select2-account-navigation').val(this.value).trigger('change');
        }
    });
}
