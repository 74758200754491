export default function checkoutValidation() {
    $ = jQuery;

    jQuery(document.body).on('checkout_error', function(e) {
        var selectedPaymentMethod = jQuery('input[name="payment_method"]:checked').val();

        if (selectedPaymentMethod === 'ppcp-gateway') {
            wcMSCupdateSession(true);
        } 
    });

    const $form = $('#js-checkout-form');

    $form.on('submit', function (e) {
        e.preventDefault();
        wcMSCupdateSession();
    });

    jQuery(document).ajaxComplete(function () {
        if (
            jQuery('body').hasClass('woocommerce-checkout') ||
            jQuery('body').hasClass('woocommerce-cart')
        ) {
            jQuery('html, body').stop();
        }
    });

    function wcMSCupdateSession(offScroll = false) {

        generateRecaptcha();

        if ($form[0]) {
            const billingPhoneEl = $form[0].querySelector(
                'input[name=billing_phone]'
            );
            if (billingPhoneEl) {
                let billingPhoneVal = billingPhoneEl.value;
                const phoneCodeEl = $form[0].querySelector(
                    '.iti__selected-dial-code'
                );
                let phoneCodeVal = phoneCodeEl ? phoneCodeEl.innerHTML : '';

                if (phoneCodeVal !== '' && billingPhoneVal !== '') {
                    billingPhoneVal = billingPhoneVal.replace(phoneCodeVal, '');
                    billingPhoneEl.value = `${phoneCodeVal}${billingPhoneVal}`;
                }
            }
        }

        let data = $form.serializeArray().concat({
            name: 'action',
            value: 'mda_wc_checkout',
        });

        let checkBillingType = data.find(item => item.name === 'billing_type');
        if (!checkBillingType)
            data.push({
                name: 'billing_type',
                value: $('input[name=billing_type]').val(),
            });

        $.ajax({
            type: 'post',
            url: base_url + '/wp-json/wc/v3/shop/checkout-form-validation',
            data: data,
            success: function (response) {
                if (response.messages.length > 0) {
                    showErrors(response.messages, $form, offScroll);
                }

                if (response.result == 'success') {
                } else {
                }
            },
        });
    }

    function generateRecaptcha(){
            grecaptcha.ready(function() {
                grecaptcha.execute('6Lf5WRgpAAAAAGGCYOkHxNuFuyvh9ZLb1WPshmT_', {action: 'submit'}).then(function(token) {

                    var recaptchaResponse = document.querySelector('input[name="recaptcha_response"]');
                    
                    if (!recaptchaResponse) {
                        recaptchaResponse = document.createElement('input');
                        recaptchaResponse.type = 'hidden';
                        recaptchaResponse.name = 'recaptcha_response';
                        document.querySelector('form.checkout').appendChild(recaptchaResponse);
                    }
                    
                    recaptchaResponse.value = token;
                });
            });
    }

    function showErrors(messages, form, offScroll = false) {
        $('.js-cf-error').remove();
        form.find('input').removeClass('-error');

        messages.forEach(function (msg, key) {
            if (msg.type == 'field') {
                const formField = form.find('[name="' + msg.key + '"]');
                if (msg.key == 'terms') {
                    formField.parent().addClass('-error');
                } else {
                    formField
                        .addClass('-error')
                        .parent()
                        .append(
                            '<span class="js-cf-error form-group__message -error">' +
                                msg.text +
                                '</span>'
                        );
                }

                if(offScroll == false){
                    if (key === 0) {
                        setTimeout(() => {
                            $('html, body').animate(
                                {
                                    scrollTop: formField.offset().top - 100,
                                },
                                1000
                            );
                        }, 100);
                    }
                }
               
            }
        });
    }

    function toggleCompanyField(checked) {
        var companyField = $('#js-checkout-form #billing_company_field');
        const nipField = $('#js-checkout-form #billing_tax_no_field');

        if (checked) {
            companyField.slideDown();
            nipField.slideDown();
        } else {
            companyField.slideUp();
            nipField.slideUp();
        }
    }

    toggleCompanyField(false);

    $('input[name=billing_type]').click(function () {
        const checked = $(this).prop('checked');
        const value = checked ? 'company' : 'private';

        $(this).val(value);
        toggleCompanyField(checked);
    });

    
    function updateCheckAllButton() {
        var totalCheckboxes = $('input[name="acceptance"], input[name="acceptance_legal"], input[name="acceptance_contract"]').length;
        var checkedCount = $('input[name="acceptance"]:checked').length + 
                           $('input[name="acceptance_legal"]:checked').length + 
                           $('input[name="acceptance_contract"]:checked').length;
        $('.js-check-all').prop('checked', checkedCount === totalCheckboxes);
    }

    $('.js-check-all').on('click',function() {
        var isChecked = $(this).is(':checked');
        $('input[name="acceptance"], input[name="acceptance_legal"], input[name="acceptance_contract"]').prop('checked', isChecked);
    });

    $('input[name="acceptance"], input[name="acceptance_legal"], input[name="acceptance_contract"]').change(function() {
        updateCheckAllButton();
    });

    updateCheckAllButton();
   
}
