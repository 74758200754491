import Aos from 'aos';
import 'select2';
import articleShare from './components/article-share';
import articleSliders from './components/articles-slider';
import backToCat from './components/back-to-cat';
import blogArticles from './components/blog-articles';
import catalogOrdering from './components/catalog-ordering';
import checkoutCountry from './components/checkout-country';
import checkoutPhone from './components/checkout-phone';
import checkoutValidation from './components/checkout-validation';
import { formCoupon } from './components/form-coupon';
import setHeaderMobile from './components/header-mobile';
import instagramSection from './components/instagramSection';
import setGalleries from './components/lightbox';
import masonryGrid from './components/masonry-grid';
import megaMenu from './components/mega-menu';
import myaccountNav from './components/myaccount-navigation';
import reviewOrderMore from './components/review-order-more';
import shopFilters from './components/shop-filters';
import singleProductGallery from './components/singleProductGallery';
import updateCart from './components/update-cart';

var documentReady = function () {
    megaMenu();
    catalogOrdering();
    myaccountNav();
    checkoutValidation();
    checkoutPhone();
    shopFilters();
    blogArticles();
    instagramSection();
    masonryGrid();
    articleSliders();
    articleShare();
    updateCart();
    reviewOrderMore();
    setHeaderMobile();
    setGalleries();
    checkoutCountry();
    singleProductGallery();
    backToCat();
    formCoupon();

    Aos.init({
        once: true,
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
