export default function updateCart(){

    document.addEventListener('change', function(e) {
        if (e.target.matches('.js-cart-dropdown-qty')) {
            const updateButton = document.querySelector("[name='update_cart']");
            if (updateButton) {
                updateButton.click();
            }
        }
    });
    
}