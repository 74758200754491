export default function backToCat() {
    $ = jQuery;

    $('#select2-back-to-cat').on('select2:select', function (e) {
        var url = $(this).val();
        if (url) {
            $('.js-back-to-cat-arrow').attr('href', url);
        }
    });
}
